<template>
  <div id="app">
    <div>
      <div class="header P_2" @click="goPrev">
          <img src="../assets/icon30.png" alt="">
          <span>企业生存能力指数</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/shengcunnengbanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">企业生存能力评分</div>
        <div class="txt2">{{Number(scoreItem.viability_score).toFixed(1) }} <span>分</span></div>
        <div class="model1">
          <div class="title">指数解读</div>
          <div class="subTitle">企业生存能力指数是基于以下纬度生成</div>
          <div class="btns">
            <div class="btn2" style="padding:0.6rem 0.8rem;background:#FFF0E5" @click="goDetail(2,'企业经营数据')">企业经营数据</div>
            <div class="btn2" @click="goDetail(2,'企业背景')" style="padding:0.6rem 1.2rem;background:#FFF0E5" >企业背景</div>
            <div class="btn2" @click="goDetail(2,'社会影响')" style="padding:0.6rem 1.2rem;background:#FFF0E5" > 社会影响</div>
          </div>
            <div>
              <div class="title2">本次企业信用评价为：<span style="color:#FF5A25">{{content2}}</span> </div>
              <div class="txt">{{content4}}</div>
            </div>
            <div class="title2" style="padding-bottom:1.3rem;">企业生存能力指数发展趋势为：<span style="color:#FF0000">增长</span> </div>
          
        </div>
        <!--<div class="model2">
          <div style="width:12rem;">
            <div class="title">企业生存能力</div>
            <div class="txt">{{content2}}</div>
          </div>
             <div  id="main" style="width: 25rem; height:10rem;"></div>  
           <img src="../assets/jingyingtoumingzhishuimag@2x.png" alt=""> 
        </div>-->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot" style="background:#FF5F11"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt"> {{content1}}</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
      <!-- <div class="btn MR_2" @click="goPrev">上一页</div>  
      <div class="btn"  @click="goNext">下一页</div>   -->
      <!-- <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>

    
  </div>
</template>
<script>
 import * as echarts from 'echarts';
 import {mapState,} from "vuex"
export default {
  mounted(){
     let {
      credit_quality_score,
      
      viability_level,
      viability_score,
      transparent_operation_score,
      ecological_chain_health_score,
      competition_score,
      operational_capability_score} = this.scoreItem
    //  let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent(viability_level)
  },
   data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:"",
    }
  },
  computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  methods:{
    goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "行业翘楚，中流砥柱，遥遥领先～"  
            this.content4 = "企业生命力强大，扎根行业深处，遥遥领先同行。"  
            this.content2 = "极好"
            this.content3 = "最强王者，遥遥领先。"  
            break;
        case 'B':
            this.content1 = "华屋生辉，宏基永固"  
            this.content4 = "企业生命力蓬勃，社会影响力不断提升，未来经营状态看好。" 
            this.content2 = "优秀" 
            this.content3 = "璀璨钻石，不断发展。"  
            break;
        case 'C':
            this.content1 = "风雨兼程，砥砺前行，未来可期～"  
            this.content4 = "企业生存能力良好，不断提升经营效率和产业水平，平稳发展。"
            this.content2 = "良好"  
            this.content3 = "华贵铂金，稳中向好。"  
            break;
        case 'D':
            this.content1 = "该企业生存能力不错，但需注意其所处行业发展趋势，可以试试看～"  
            this.content4 = "企业生存能力不错，仍需注意所处行业发展趋势，砥砺前行。"  
            this.content2 = "中等"
            this.content3 = "荣耀黄金，未来可期。"  
          break;
         case 'E':
            this.content1 = "生存不易，企业负重前行"  
            this.content4 = "生存不易，企业负重前行，不断发展。"  
            this.content2 = "较差"
            this.content3 = "坚韧黄铜，砥砺前行。"  
          break;
        default:
            
        }
         
       
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
    goNext(){
      this.$router.push("/businessCapacity")
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000,   },
            { name: '生存能力评分', max: 1000,color:"#FF3E09",},
            { name: '企业透明指数', max: 1000 },
            { name: '生态链健康指数', max: 1000 },
            { name: '同业竞争评分', max: 1000 },
            { name: '经营能力评分', max: 1000 }
        ],
        center: ['50%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(255,71,0, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(255,71,0, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
     
  }
}
</script>
<style lang="scss" scoped>
#app{
  background-color: #fff;
}
  .companyTitle{
  display: flex;
  align-items: center;
 }
</style>